import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Contact from '../components/contact'
import useContactData from '../components/custom-hooks/useContactData'

const ContactPage = () => {
    const payload = useContactData()

    return (
        <Layout
            color={payload.wpPage.contact_page.contactTextColor}
            backgroundColor={payload.wpPage.contact_page.contactBackgroundColor}
        >
            <SEO title="Contact" />
            <Contact payload={payload} />
        </Layout>
    )
}

export default ContactPage
