import React, { useState, useContext } from 'react'
// import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
// import Maps from './map'
import useWindowSize from './custom-hooks/useWindowSize'
import useMetaData from './custom-hooks/useMetaData'
import { ComponentScroller } from './project-components/chapter-types/scroller-base'
import { injectSanitiseHtml, isMobileSize } from '../utils'
import ScrollContext from './context/ScrollContext'
import {
    effects,
    useAnimationManager,
} from './custom-hooks/useAnimationManager'
import useMouseOver from './custom-hooks/useMouseOver'
import Social from './social'
import ImageWithSideText from './project-components/chapter-types/imageWithSideText'
import 'react-multi-carousel/lib/styles.css'

import * as contactStyles from './contact.module.scss'

const ContactPage = ({ payload }) => {
    const scrollY = useContext(ScrollContext)
    const size = useWindowSize()
    const meta = useMetaData()
    const [deviceIsMobile, setDevice] = useState(false)
    // const [countryState, setCountry] = useState('All')
    const [mouseOverState, setMouseOverList] = useState([])

    const animatedElements = {
        runOpeningAnimation: {
            id: '',
            effect: effects.ON_OPEN,
        },
    }

    const mouseOverList = []

    for (
        let index = 0;
        index < payload.wpPage.contact_page.locations.length;
        index++
    ) {
        animatedElements[`locationImagesNo${index}`] = {
            id: `location-images-${index}`,
            effect: effects.ON_LOW_REACHED,
        }
        animatedElements[`locationTextNo${index}`] = {
            id: `location-text-${index}`,
            effect: effects.ON_LOW_REACHED,
        }
        mouseOverList[index] = useMouseOver(`location-images-${index}`)
    }

    if (mouseOverList.length === mouseOverState.length) {
        let inclusions = 0
        mouseOverList.forEach((item, index) => {
            if (
                mouseOverState[index] !== undefined &&
                mouseOverState[index] === item
            ) {
                inclusions += 1
            }
        })
        if (inclusions !== mouseOverList.length) {
            setMouseOverList(mouseOverList)
        }
    } else {
        setMouseOverList(mouseOverList)
    }

    const animationStates = useAnimationManager(animatedElements, scrollY)

    if (isMobileSize(size.width) !== deviceIsMobile) {
        setDevice(!deviceIsMobile)
    }

    const vwTOpx = (value) => {
        const result = (size.width * value) / 100
        return result
    }

    const beforeChangeTransform = 0
    const beforeChangeTransformMobile = 0
    const beforeChangeIncrement = vwTOpx(70) - 45
    const beforeChangeIncrementMobile = vwTOpx(40) - 10

    const carouselStyle = contactStyles.carouselContainerWide
    const itemStyle = contactStyles.itemClassWide
    const scrollStyle = contactStyles.scrollImageWide

    const contactList = payload.wpPage.contact_page.contacts.map(
        (contact, index) => (
            <div
                className={
                    animationStates.runOpeningAnimation
                        ? contactStyles.contact
                        : contactStyles.contactClosed
                }
                style={{
                    transitionDelay: `${0.5 + index * 0.1}s`,
                }}
                key={`contact-item-block-${index}`}
            >
                {injectSanitiseHtml(contact.title, 'h3')}
                {injectSanitiseHtml(contact.subtext, 'div')}
                <p>{contact.phone}</p>
                <a href={`mailto:${contact.mail}`}>{contact.mail}</a>
            </div>
        )
    )

    const getLocationImagesWithMap = (location, isOdd) => {
        const imageDataList = location.locationImages.map((item) => (
            <GatsbyImage
                image={item.image.localFile.childImageSharp.gatsbyImageData}
                key={item.image.localFile.childImageSharp.gatsbyImageData.id}
                className={scrollStyle}
                draggable={false}
            />
        ))
        // const MapImageComponent = (
        //     <Maps
        //         apiKey={meta.site.siteMetadata.mapsKey}
        //         lat={parseFloat(location.mapData.lat, 10)}
        //         lng={parseFloat(location.mapData.lng, 10)}
        //     />
        // )
        // DONT USE MAP
        // if (isOdd) {
        //     imageDataList.push(MapImageComponent)
        // } else {
        //     imageDataList.unshift(MapImageComponent)
        // }
        return imageDataList
    }

    const locationList = payload.wpPage.contact_page.locations.map(
        (location, index) => {
            // const isOdd = index % 2 === 1
            const isOdd = false
            // const sliderClass = isOdd
            //     ? contactStyles.sliderClass
            //     : contactStyles.sliderClass

            return (
                <div
                    style={{ overflow: 'hidden' }}
                    key={`contact-location-block-${index}`}
                >
                    <div
                        className={
                            animationStates[`locationImagesNo${index}`]
                                ? contactStyles.carouselContainer
                                : contactStyles.carouselContainerClosed
                        }
                        id={`location-images-${index}`}
                    >
                        {location.locationImages && (
                            <ComponentScroller
                                partialVisbile
                                itemClass={itemStyle}
                                carouselContainer={carouselStyle}
                                scrollContainer={contactStyles.scrollContainer}
                                sliderClass={contactStyles.sliderClass}
                                centerMode={false}
                                isMouseOver={mouseOverState[index]}
                                itemsOnMobile={1}
                                itemsOnDesktop={1}
                                itemsOnTablet={1}
                                beforeChangeTransform={
                                    deviceIsMobile
                                        ? beforeChangeTransformMobile
                                        : beforeChangeTransform
                                }
                                beforeChangeIncrement={
                                    deviceIsMobile
                                        ? beforeChangeIncrementMobile
                                        : beforeChangeIncrement
                                }
                                gotoLast={isOdd}
                            >
                                {getLocationImagesWithMap(location, isOdd)}
                                {/* {location.locationImages.map(item => (
                                    <Img
                                        fluid={
                                            item.image.localFile.childImageSharp
                                                .fluid
                                        }
                                        key={
                                            item.image.localFile.childImageSharp
                                                .fluid.src
                                        }
                                        className={scrollStyle}
                                        draggable={false}
                                    />
                                ))} */}
                            </ComponentScroller>
                        )}
                    </div>
                    <div className={contactStyles.location}>
                        <div
                            className={
                                animationStates[`locationTextNo${index}`]
                                    ? contactStyles.title
                                    : contactStyles.titleClosed
                            }
                            style={isOdd ? { textAlign: 'end' } : {}}
                            id={`location-text-${index}`}
                        >
                            {injectSanitiseHtml(location.title, 'h3')}
                        </div>
                        <div
                            className={
                                animationStates[`locationTextNo${index}`]
                                    ? contactStyles.details
                                    : contactStyles.detailsClosed
                            }
                            style={isOdd ? { textAlign: 'end' } : {}}
                        >
                            <a href={`mailto:${location.mail}`}>
                                {location.mail}
                            </a>
                            <p>{location.phone}</p>
                            {injectSanitiseHtml(location.address, 'p')}
                        </div>
                    </div>
                </div>
            )
        }
    )
    // const positions = payload.allWordpressWpJobs.edges.map(edge => {
    //     if (countryState === 'All' || countryState === edge.node.contact_page.location) {
    //         return (
    //             <div className={contactStyles.positions} key={`positions-${edge.node.title}`}>
    //                 <Link
    //                     style={{ textDecoration: 'none' }}
    //                     to={`/${edge.node.slug}`}
    //                 >
    //                     <h3 style={{ cursor: 'pointer' }}>{edge.node.title}</h3>
    //                     <h4 style={{ cursor: 'pointer' }}>+</h4>
    //                 </Link>
    //             </div>
    //         )
    //     }
    //     return null
    // })

    // const jobLocations = ['All']
    // payload.allWordpressWpJobs.edges.forEach(edge => {
    //     if (!jobLocations.includes(edge.node.contact_page.location)) {
    //         jobLocations.push(edge.node.contact_page.location)
    //     }
    // })

    // const countryButtons = jobLocations.map(county => (
    //     <p
    //         onClick={() => setCountry(county)}
    //         style={
    //             county === countryState
    //                 ? {
    //                       fontWeight: 'bold',
    //                       color: '#0064ff',
    //                       cursor: 'pointer',
    //                   }
    //                 : { cursor: 'pointer', color: '#a4a4a4' }
    //         }
    //         key={county}
    //     >
    //         {county}
    //     </p>
    // ))

    return (
        <div
            style={{
                backgroundColor:
                    payload.wpPage.contact_page.contactBackgroundColor,
            }}
            className={contactStyles.margin}
        >
            <div className={contactStyles.heroContainer}>
                <div
                    className={
                        animationStates.runOpeningAnimation
                            ? contactStyles.titleBox
                            : contactStyles.titleBoxClosed
                    }
                >
                    {injectSanitiseHtml(
                        payload.wpPage.contact_page.title,
                        'h1'
                    )}
                </div>
                <div className={contactStyles.contacts}>{contactList}</div>
                {locationList}
                {payload.wpPage.contact_page.showMediaBox ? (
                    <ImageWithSideText
                        index={1}
                        media={[
                            {
                                __typename: 'custom_MixedMedia_Video',
                                noControls: true,
                                autoplay: true,
                                loop: true,
                                video: payload.wpPage.contact_page.video,
                            },
                        ]}
                        showHeadings={
                            payload.wpPage.contact_page.showHeadings || false
                        }
                        heading={payload.wpPage.contact_page.heading || ''}
                        subHeading={
                            payload.wpPage.contact_page.subheading || ''
                        }
                        backgroundColor={
                            payload.wpPage.contact_page.contactBackgroundColor
                        }
                        color={payload.wpPage.contact_page.textColour}
                        show={payload.wpPage.contact_page.showText || false}
                        description={
                            payload.wpPage.contact_page.description || ''
                        }
                        stickyText={false}
                        horizontalAlignment={'left'}
                        key={`imageside-chapter-${1}`}
                    />
                ) : null}
                {/* <div style={{ minHeight: '80px' }} id="hiring" /> */}
                {/* <div className={contactStyles.wellcome}>
                    {injectSanitiseHtml(payload.wpPage.contact_page.description)}
                </div> */}
                {/* <div className={contactStyles.positionsContainer}>
                    <div className={contactStyles.selectors}>
                        {countryButtons}
                    </div>
                    <div className={contactStyles.positionsBox}>
                        {positions}
                    </div>
                </div> */}
            </div>
            {/* <Social color="white" /> */}
        </div>
    )
}

export default ContactPage
