// extracted by mini-css-extract-plugin
export const textContainer = "text-module--text-container--3_Oug";
export const textContainerPadded = "text-module--text-container-padded--3pOEL";
export const textContainerColumn = "text-module--text-container-column--2RYVH";
export const textContainerColumnSticky = "text-module--text-container-column-sticky--3SzjA";
export const textContainerColumnPadded = "text-module--text-container-column-padded--kNIB4";
export const titleBox = "text-module--title-box--2pMek";
export const descriptionBox = "text-module--description-box--3WJ0b";
export const slantedTextUpdate = "text-module--slanted-text-update--1y6ns";
export const animatedHeader = "text-module--animated-header---RDB2";
export const animatedHeaderClosed = "text-module--animated-header-closed--18L42";
export const animatedDescription = "text-module--animated-description--9qC4T";
export const animatedDescriptionClosed = "text-module--animated-description-closed--fm-T0";