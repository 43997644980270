import React from 'react'
import { injectSanitiseHtml } from '../../../utils'

import * as textStyles from './text.module.scss'

const TextComponent = (props) => {
    let mainStyle = textStyles.textContainer
    let widthStyle = {
        width: '50%',
    }
    let titleVersion = (
        <p style={{ color: `${props.color}` }}>{props.heading}</p>
    )

    if (!props.subHeading) {
        mainStyle = textStyles.textContainerColumn
        widthStyle = {
            width: '100%',
            textAlign: 'right',
            color: `${props.color}`,
        }
        titleVersion = (
            <h2 style={{ color: `${props.color}` }}>{props.heading}</h2>
        )
    }

    let descriptionStyle = textStyles.descriptionBox
    if (props.slantedText) {
        descriptionStyle = [
            textStyles.descriptionBox,
            textStyles.slantedTextUpdate,
        ].join(' ')
    }

    // Manage padding of standalone text boxes
    if (props.padding) {
        mainStyle = textStyles.textContainerPadded
        widthStyle = {
            width: '50%',
            color: `${props.color}`,
        }
        if (!props.subHeading || props.column) {
            mainStyle = textStyles.textContainerColumnPadded
            widthStyle = {
                width: '100%',
                textAlign: 'right',
                color: `${props.color}`,
            }
            titleVersion = (
                <h2 style={{ color: `${props.color}` }}>{props.heading}</h2>
            )
        }
    }

    if (props.column) {
        mainStyle = textStyles.textContainerColumn
        widthStyle = {
            width: '100%',
            textAlign: 'left',
            color: `${props.color}`,
        }
        if (props.sticky) {
            mainStyle = textStyles.textContainerColumnSticky
        }
    }

    const innerBlockIsAnimated = () => {
        if (props.isAnimated) {
            return (
                <React.Fragment>
                    {props.showHeadings && props.heading !== '' ? (
                        <div className={textStyles.titleBox} style={widthStyle}>
                            <div
                                className={
                                    props.animationTrigger
                                        ? textStyles.animatedHeader
                                        : textStyles.animatedHeaderClosed
                                }
                            >
                                {titleVersion}
                            </div>
                        </div>
                    ) : null}

                    <div className={descriptionStyle}>
                        <div
                            className={
                                props.animationTrigger
                                    ? textStyles.animatedDescription
                                    : textStyles.animatedDescriptionClosed
                            }
                        >
                            {props.showHeadings ? (
                                <h1>{props.subHeading}</h1>
                            ) : null}
                            {props.showText
                                ? injectSanitiseHtml(props.description)
                                : null}
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                {props.showHeadings && props.heading !== '' ? (
                    <div className={textStyles.titleBox} style={widthStyle}>
                        {titleVersion}
                    </div>
                ) : null}
                <div className={descriptionStyle}>
                    {props.showHeadings && props.subHeading !== '' ? (
                        <h1>{props.subHeading}</h1>
                    ) : null}
                    {props.showText
                        ? injectSanitiseHtml(props.description)
                        : null}
                </div>
            </React.Fragment>
        )
    }

    return (
        <div
            className={mainStyle}
            style={{
                background: `${props.backgroundColor}`,
                color: `${props.color}`,
            }}
            key={`textbox-${props.index}`}
        >
            {innerBlockIsAnimated()}
        </div>
    )
}

export default TextComponent

TextComponent.defaultProps = {
    color: '#262626',
    padding: true,
    isAnimated: false,
    animationTrigger: true,
}
