// extracted by mini-css-extract-plugin
export const heroContainer = "contact-module--hero-container--156Zh";
export const titleBox = "contact-module--title-box--2q5d0";
export const titleBoxClosed = "contact-module--title-box-closed--36N3h";
export const contacts = "contact-module--contacts--1Rzc5";
export const contact = "contact-module--contact--2zfxa";
export const contactClosed = "contact-module--contact-closed--1qCCp";
export const location = "contact-module--location--1jfiF";
export const carouselContainer = "contact-module--carousel-container--jMF6f";
export const carouselContainerClosed = "contact-module--carousel-container-closed--2Kmqn";
export const title = "contact-module--title--2qMmF";
export const titleClosed = "contact-module--title-closed--17M3m";
export const details = "contact-module--details--1zYTU";
export const detailsClosed = "contact-module--details-closed--293Zs";
export const itemClassWide = "contact-module--item-class-wide--310RY";
export const carouselContainerWide = "contact-module--carousel-container-wide--KgWPV";
export const sliderClass = "contact-module--slider-class--1vq3G";
export const scrollImageWide = "contact-module--scroll-image-wide--1zTN6";
export const margin = "contact-module--margin--3XTTj";
export const scrollContainer = "contact-module--scroll-container--3L2Vd";
export const scrollContainerEnd = "contact-module--scroll-container-end--21rgT";