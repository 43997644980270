import { useStaticQuery, graphql } from 'gatsby'

export default () => {
    const payload = useStaticQuery(
        graphql`
            query {
                wpPage(title: { eq: "Contact" }) {
                    contact_page {
                        title
                        contactBackgroundColor
                        contactTextColor
                        contacts {
                            mail
                            phone
                            title
                            subtext
                        }
                        locations {
                            address
                            mail
                            phone
                            title
                            mapData {
                                latitude
                                longitude
                                streetAddress
                            }
                            locationImages {
                                image {
                                    localFile {
                                        childImageSharp {
                                            id
                                            gatsbyImageData(
                                                layout: CONSTRAINED
                                                width: 900
                                            )
                                        }
                                    }
                                }
                            }
                        }
                        description
                        textColour
                        showMediaBox
                        heading
                        subheading
                        showText
                        showHeadings
                        video {
                            mediaItemUrl
                        }
                    }
                }
            }
        `
    )
    return payload
}
