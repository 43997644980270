import React, { useContext } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import ScrollContext from '../../context/ScrollContext'
import Text from './text'
import {
    effects,
    useAnimationManager,
} from '../../custom-hooks/useAnimationManager'

import * as imageWithSideTextStyles from './imageWithSideText.module.scss'

export default (props) => {
    const scrollY = useContext(ScrollContext)

    const animatedElements = {
        imageWithSideTextBlock: {
            id: `image-side-text-box-${props.index}`,
            effect: effects.ON_TOP_REACHED,
        },
    }

    const animationStates = useAnimationManager(animatedElements, scrollY)

    const isAnyTextAvailable = () => {
        if (!props.showHeadings && !props.show) {
            return false
        }
        return true
    }

    const getTextElement = () => {
        return isAnyTextAvailable() ? (
            <Text
                backgroundColor={props.backgroundColor}
                color={props.color || 'black'}
                showHeadings={props.showHeadings || false}
                heading={props.heading || ''}
                subHeading={props.subHeading || ''}
                showText={props.show || false}
                description={props.description || ''}
                padding={false}
                column
                slantedText
                sticky={props.stickyText}
            />
        ) : null
    }

    const getImageBox = (align) => {
        let margin = {
            marginLeft: 0,
            marginRight: '20px',
        }
        if (align === 'left') {
            margin = {
                marginLeft: '20px',
                marginRight: '20px',
            }
        }
        if (props.media) {
            return (
                <div
                    style={margin}
                    className={imageWithSideTextStyles.imageBox}
                >
                    {props.media.map((media) => {
                        if (media.__typename.match(/.*MixedMedia_Image$/)) {
                            return (
                                <GatsbyImage
                                    image={
                                        media.image.localFile.childImageSharp
                                            .gatsbyImageData
                                    }
                                    key={
                                        media.image.localFile.childImageSharp
                                            .gatsbyImageData
                                    }
                                    width={1600}
                                    className={
                                        imageWithSideTextStyles.galleryItem
                                    }
                                />
                            )
                        }
                        if (media.__typename.match(/.*MixedMedia_Video$/)) {
                            return (
                                <video
                                    controls={!media.noControls}
                                    autoPlay={media.autoplay}
                                    playsInline={media.autoplay}
                                    loop={media.loop}
                                    muted="muted"
                                    className={
                                        imageWithSideTextStyles.galleryItem
                                    }
                                    key={media.video.mediaItemUrl}
                                >
                                    <source
                                        src={media.video.mediaItemUrl}
                                        type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                                    />
                                </video>
                            )
                        }
                    })}
                </div>
            )
        }
        return null
    }

    const getElementsInOrder = (align) => {
        if (align === 'right') {
            return (
                <div className={imageWithSideTextStyles.collectionContainer}>
                    {getImageBox('right')}
                    {getTextElement()}
                </div>
            )
        }
        return (
            <div
                style={{
                    background: `${props.backgroundColor}`,
                    color: `${props.color || 'black'}`,
                }}
                className={imageWithSideTextStyles.collectionContainer}
                id={animatedElements.imageWithSideTextBlock.id}
                key={`imagesidetext-${props.index}`}
            >
                {/* <div className={imageWithSideTextStyles.animatedBox}> */}
                {getTextElement()}
                {getImageBox('left')}
                {/* </div> */}
            </div>
        )
    }

    return getElementsInOrder(props.horizontalAlignment)
}
